const SvgIcons = {
  applePay: (
    <svg width="64" height="41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.129 25.416h1.23v-3.52h2.489c1.852 0 3.165-1.306 3.165-3.18v-.013c0-1.873-1.313-3.151-3.165-3.151h-3.72v9.864Zm3.404-8.77c1.408 0 2.222.772 2.222 2.07v.014c0 1.3-.814 2.072-2.222 2.072H30.36v-4.157h2.174Zm7.096 8.9c.991 0 1.764-.43 2.228-1.217h.11v1.087h1.19v-5.045c0-1.531-1.006-2.454-2.803-2.454-1.573 0-2.694.78-2.885 1.935l-.007.04h1.19l.006-.02c.192-.574.773-.902 1.654-.902 1.101 0 1.655.492 1.655 1.401v.67l-2.112.13c-1.716.102-2.687.861-2.687 2.174v.013c0 1.34 1.06 2.188 2.46 2.188Zm-1.244-2.215v-.014c0-.731.492-1.128 1.613-1.196l1.969-.123v.67c0 1.053-.882 1.846-2.092 1.846-.855 0-1.49-.438-1.49-1.183Zm7.3 4.669c1.306 0 1.915-.479 2.523-2.133l2.878-7.82h-1.251l-2.017 6.063h-.109l-2.024-6.063h-1.27l2.727 7.376-.137.437c-.267.848-.677 1.156-1.354 1.156-.164 0-.348-.007-.492-.035v.978c.164.027.37.041.526.041ZM18.16 15.23c.416-.034.726-.084.931-.149.323-.111.648-.328.976-.65.38-.38.659-.794.835-1.239.175-.445.263-.855.263-1.23 0-.059-.003-.117-.009-.176a1.054 1.054 0 0 0-.043-.202c-1.05.24-1.8.685-2.255 1.336-.454.65-.686 1.42-.698 2.31Zm-2.127 11.354c.305 0 .702-.101 1.191-.303.49-.202.918-.303 1.287-.303.37 0 .822.096 1.358.29.536.193.956.29 1.261.29.768 0 1.54-.59 2.32-1.767.515-.785.884-1.537 1.107-2.258-.533-.158-1.008-.536-1.423-1.134a3.44 3.44 0 0 1-.624-2.012c0-.68.196-1.3.588-1.863.217-.31.56-.665 1.029-1.063a4.813 4.813 0 0 0-.94-.905 3.315 3.315 0 0 0-1.925-.58c-.44 0-.958.102-1.556.307-.597.206-1.028.308-1.291.308-.205 0-.62-.09-1.244-.272-.623-.182-1.155-.273-1.595-.273-1.03 0-1.884.434-2.561 1.3-.677.868-1.015 1.99-1.015 3.366 0 1.482.442 2.994 1.327 4.535.884 1.558 1.787 2.337 2.706 2.337Z"
        fill="#ffffff"
      />
      <rect
        x=".5"
        y="1"
        width="62.871"
        height="39"
        rx="9.5"
        stroke="currentColor"
      />
    </svg>
  ),
  checkbox: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="9" stroke="#C9C9C9" strokeWidth="1.5" />
    </svg>
  ),
  checkboxCheckedBlue: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="10" fill="#1A72FF" />
      <path
        d="M6.45374 11.4618L9.36862 13.5473L13.5321 7.0957"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  checkboxCheckedWhite: (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
      <circle cx="10.25" cy="10.8289" r="10" fill="white" />
      <path
        d="M6.70374 12.2909L9.61862 14.3764L13.7821 7.92474"
        stroke="#0D0C22"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  checkboxSquare: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect
        x="0.75"
        y="0.75"
        width="18.5"
        height="18.5"
        rx="3.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  ),
  checkboxSquareChecked: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect width="20" height="20" rx="4" fill="#1A72FF" />
      <path
        d="M5.04517 11.5965L9.126 14.5161L14.9549 5.48389"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  closeRed: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.59961 5.59961L10.3996 10.3996"
        stroke="#FF3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4004 5.59961L5.60039 10.3996"
        stroke="#FF3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="0.75"
        y="0.75"
        width="14.5"
        height="14.5"
        rx="7.25"
        stroke="#FF3D3D"
        strokeWidth="1.5"
      />
    </svg>
  ),
  creditCardGeneric: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="41"
      fill="none"
      className="injected-svg"
      data-src="/images/icon-stripe-1.svg"
    >
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M61.49 5.256H1.374v11.282H61.49V5.256z"
        ></path>
        <path
          fill="#BDBDBD"
          d="M14.172 33.918a6 6 0 100-12 6 6 0 000 12z"
        ></path>
        <path
          fill="#3D3D3D"
          d="M21.172 33.918a6 6 0 100-12 6 6 0 000 12z"
        ></path>
        <path
          fill="#707070"
          fillRule="evenodd"
          d="M17.673 23.043a5.992 5.992 0 012.499 4.873 5.992 5.992 0 01-2.5 4.874 5.988 5.988 0 01-2.5-4.874 5.99 5.99 0 012.389-4.792l.112-.081z"
          clipRule="evenodd"
        ></path>
      </g>
      <rect
        width="59"
        height="39"
        x="1.371"
        y="1"
        stroke="currentColor"
        rx="9.5"
      ></rect>
      <defs>
        <clipPath id="a">
          <rect
            width="60"
            height="40"
            x="0.871"
            y="0.5"
            fill="#fff"
            rx="10"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  ),
  crossSmall: (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M3.6001 3.59998L8.4001 8.39997"
        stroke="#0D0C22"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3999 3.59998L3.5999 8.39997"
        stroke="#0D0C22"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  exclamation: (
    <svg
      width="4"
      height="14"
      viewBox="0 0 4 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.33308 12.5188C3.33308 13.337 2.6698 14.0003 1.8516 14.0003C1.0334 14.0003 0.370117 13.337 0.370117 12.5188C0.370117 11.7006 1.0334 11.0374 1.8516 11.0374C2.6698 11.0374 3.33308 11.7006 3.33308 12.5188Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.374199 2.25719C0.310962 1.39851 0.990581 0.666992 1.8516 0.666992C2.71412 0.666992 3.39427 1.40096 3.32871 2.26099L2.94986 8.5322C2.90579 9.11028 2.4235 9.55663 1.84374 9.55588C1.26399 9.55513 0.782846 9.10755 0.740266 8.52936L0.374199 2.25719Z"
      />
    </svg>
  ),
  funkoCrown: (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.82 8.581a1.665 1.665 0 0 0-1.761.486 1.684 1.684 0 0 0-.266 1.814c-1.596 1.45-3.071 2.525-3.848 2.275-1.006-.323-.247-3.035.736-5.509a1.819 1.819 0 0 0 2.009-1.253 1.83 1.83 0 0 0-.921-2.188 1.814 1.814 0 0 0-2.295.574 1.834 1.834 0 0 0 .21 2.367c-1.307 2.376-2.667 4.434-3.86 4.086l-.042-.01c-1.2-.386-1.12-2.865-.807-5.58a1.828 1.828 0 0 0 1.572-1.78A1.821 1.821 0 0 0 6 3.24c-.29.854.081 1.792.875 2.212-.632 2.585-1.583 5.235-2.589 4.911-.77-.248-1.346-1.972-1.807-4.073a1.681 1.681 0 0 0 .785-1.967 1.67 1.67 0 0 0-1.759-1.17A1.674 1.674 0 0 0 .01 4.643a1.678 1.678 0 0 0 1.344 1.825l.297 7.048 11.612 3.746 4.33-5.572a1.664 1.664 0 0 0 1.856-.261 1.683 1.683 0 0 0-.626-2.85l-.002.001Z"
        fill="currentColor"
      />
    </svg>
  ),
  invalid: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="9.25" stroke="#FF3D3D" strokeWidth="1.5" />
      <path
        d="M7 7L13 13"
        stroke="#FF3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13 7L7 13"
        stroke="#FF3D3D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ),
  available: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="9" stroke="#3FB95F" strokeWidth="1.45" />
      <path
        d="M7 10.8334L9.56972 12.7778L14 7"
        stroke="#3FB95F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  unavailable: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63765 2.40618C8.67057 0.455105 11.4656 0.455098 12.4985 2.40618L19.1767 15.0205C20.1463 16.852 18.8186 19.0572 16.7463 19.0572H3.38988C1.31755 19.0572 -0.010157 16.852 0.959461 15.0205L7.63765 2.40618ZM11.1728 3.10801C10.7033 2.22116 9.43284 2.22116 8.96333 3.10801L2.28514 15.7224C1.84441 16.5549 2.44791 17.5572 3.38988 17.5572H16.7463C17.6882 17.5572 18.2917 16.5549 17.851 15.7224L11.1728 3.10801Z"
        fill="#FF3D3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.98541 7.16535C8.93907 6.53608 9.43712 6 10.0681 6C10.7002 6 11.1986 6.53788 11.1506 7.16813L10.8729 11.7639C10.8406 12.1875 10.4872 12.5146 10.0623 12.514C9.63748 12.5135 9.28488 12.1855 9.25368 11.7618L8.98541 7.16535Z"
        fill="#FF3D3D"
      />
      <path
        d="M11.1538 14.7182C11.1538 15.3178 10.6677 15.8039 10.0681 15.8039C9.46849 15.8039 8.98242 15.3178 8.98242 14.7182C8.98242 14.1186 9.46849 13.6326 10.0681 13.6326C10.6677 13.6326 11.1538 14.1186 11.1538 14.7182Z"
        fill="#FF3D3D"
      />
    </svg>
  ),
  revealLeft: (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
      <path
        d="M28.5 17L19.5 26L28.5 35"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.25"
        y="1.25"
        width="49.5"
        height="49.5"
        rx="24.75"
        stroke="white"
        strokeWidth="2.5"
      />
    </svg>
  ),
  revealRight: (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
      <path
        d="M23.5 35L32.5 26L23.5 17"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="50.75"
        y="50.75"
        width="49.5"
        height="49.5"
        rx="24.75"
        transform="rotate(-180 50.75 50.75)"
        stroke="white"
        strokeWidth="2.5"
      />
    </svg>
  ),
  dpStatic: (
    <svg width="46" height="40" viewBox="0 0 46 40">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.8483 21.965C40.8751 26.2491 36.9407 29.1054 32.6934 28.8706L36.5482 11.7695C38.2309 12.4543 39.6594 13.6338 40.6616 15.1956C41.959 17.218 42.3805 19.6221 41.8483 21.965ZM26.8122 36.4389C25.9767 36.9662 24.9858 37.1364 24.0218 36.9171C22.0363 36.4661 20.7865 34.4837 21.2361 32.498L24.4734 18.1937C25.4251 14.0031 29.154 11.1108 33.2286 11.1108C33.3622 11.1108 33.4964 11.1148 33.6306 11.1211L28.4451 34.1263C28.2277 35.0901 27.648 35.9114 26.8122 36.4389ZM11.4336 28.8797L16.6195 5.87414C16.8367 4.91047 17.4166 4.08903 18.2522 3.56175C19.0881 3.03426 20.079 2.86441 21.0428 3.08352C23.0283 3.53433 24.2779 5.51691 23.8285 7.50258L20.5912 21.8067C19.6086 26.1346 15.6636 29.0782 11.4336 28.8797ZM4.40141 24.8042C3.10401 22.782 2.68247 20.3779 3.21471 18.035C4.18744 13.751 8.12188 10.8946 12.3694 11.1292L8.51477 28.2303C6.83211 27.5457 5.40321 26.366 4.40141 24.8042ZM43.1776 13.5815C41.4442 10.8798 38.7577 9.0287 35.6132 8.36928C32.2355 7.66081 28.8578 8.5144 26.2268 10.4393L26.7421 8.16191C27.5551 4.57007 25.2946 0.984197 21.7032 0.168533C19.9599 -0.227446 18.1672 0.0796904 16.6556 1.03367C15.1439 1.98765 14.0951 3.47324 13.7022 5.2167L13.0339 8.18088C7.17194 7.60536 1.63853 11.4804 0.299913 17.3733C-0.411242 20.5036 0.152117 23.716 1.88569 26.4184C3.61884 29.1204 6.30536 30.9714 9.4501 31.6306C10.246 31.7976 11.0416 31.8778 11.8276 31.8778C14.3775 31.8778 16.8249 31.0337 18.8359 29.5631L18.321 31.8378C17.5082 35.4298 19.7687 39.0159 23.3601 39.8318C23.8565 39.9443 24.3567 40 24.8535 40C26.1012 40 27.3263 39.6485 28.4074 38.9662C29.919 38.0123 30.968 36.5267 31.3611 34.7832L32.0294 31.8192C37.8949 32.3952 43.425 28.5195 44.7634 22.6268C45.4743 19.4963 44.9112 16.2839 43.1776 13.5815Z"
      />
    </svg>
  ),
  expand: (
    <svg width="5" height="11" viewBox="0 0 5 11" fill="none">
      <path
        d="M0.8125 10L4.1875 5.5L0.812501 1"
        stroke="#0D0C22"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  filterAddress: (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M14.3012 5.3033C14.3012 7.58147 12.8237 9.62512 11.0012 9.62512C9.17863 9.62512 7.70117 7.58147 7.70117 5.3033C7.70117 3.02512 9.17863 2.06262 11.0012 2.06262C12.8237 2.06262 14.3012 3.02512 14.3012 5.3033Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M17.875 17.0976C17.875 19.0704 16.1913 20.2065 11 20.2065C5.80867 20.2065 4.125 19.0704 4.125 17.0976C4.125 15.1248 7.20304 13.0623 11 13.0623C14.797 13.0623 17.875 15.1248 17.875 17.0976Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  ),
  filterCollections: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect
        x="18.25"
        y="18.2502"
        width="16.5"
        height="16.5"
        rx="1.25"
        transform="rotate(-180 18.25 18.2502)"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 11.5002C6 11.224 6.22386 11.0002 6.5 11.0002H8.5C8.77614 11.0002 9 11.224 9 11.5002V13.5002C9 13.7763 8.77614 14.0002 8.5 14.0002H6.5C6.22386 14.0002 6 13.7763 6 13.5002V11.5002Z"
        fill="currentColor"
      />
      <path
        d="M6 6.50018C6 6.22404 6.22386 6.00018 6.5 6.00018H8.5C8.77614 6.00018 9 6.22404 9 6.50018V8.50018C9 8.77633 8.77614 9.00018 8.5 9.00018H6.5C6.22386 9.00018 6 8.77633 6 8.50018V6.50018Z"
        fill="currentColor"
      />
      <path
        d="M11 11.5002C11 11.224 11.2239 11.0002 11.5 11.0002H13.5C13.7761 11.0002 14 11.224 14 11.5002V13.5002C14 13.7763 13.7761 14.0002 13.5 14.0002H11.5C11.2239 14.0002 11 13.7763 11 13.5002V11.5002Z"
        fill="currentColor"
      />
      <path
        d="M11 6.50018C11 6.22404 11.2239 6.00018 11.5 6.00018H13.5C13.7761 6.00018 14 6.22404 14 6.50018V8.50018C14 8.77633 13.7761 9.00018 13.5 9.00018H11.5C11.2239 9.00018 11 8.77633 11 8.50018V6.50018Z"
        fill="currentColor"
      />
    </svg>
  ),
  filterRarity: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M12.2224 3.25006L10.0001 8.87506L4.44453 11.1252L10.0002 13.3751L12.2223 19.0001L14.4445 13.3751L20 11.1252L14.4445 8.87524L12.2224 3.25006Z"
        fill="currentColor"
      />
      <path
        d="M2.77777 6.06256L3.88888 8.87506L4.99999 6.06256L7.77776 4.93756L4.99999 3.81256L3.88893 1.00006L2.77777 3.81256L0 4.93756L2.77777 6.06256Z"
        fill="currentColor"
      />
      <path
        d="M4.72227 15.6251L3.88893 13.3751L3.05561 15.6251L1.11117 16.1876L3.05561 17.0313L3.88893 19.0001L4.72227 17.0313L6.66671 16.1876L4.72227 15.6251Z"
        fill="currentColor"
      />
    </svg>
  ),
  filterVariants: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3 4H10.5M17 4C16.4526 4 15.9613 4 15.5 4M3 10H4.5M17 10C14.4525 10 11.489 10 9.5 10M3 16C6.15987 16 8.01739 16 10.5 16M17 16C16.6576 16 16.3247 16 16 16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13"
        cy="4"
        r="2.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="7"
        cy="10"
        r="2.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13"
        cy="16"
        r="2.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  filterView: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 3.75281L20.3999 8.39993L12 13.293L3.60013 8.39993L12 3.75281Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M20.3999 11.9999L12 16.893L3.60013 11.9999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3999 15.5999L12 20.493L3.60013 15.5999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  filterTraits: (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M3 4H10.5M17 4C16.4526 4 15.9613 4 15.5 4M3 10H4.5M17 10C14.4525 10 11.489 10 9.5 10M3 16C6.15987 16 8.01739 16 10.5 16M17 16C16.6576 16 16.3247 16 16 16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13"
        cy="4"
        r="2.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="7"
        cy="10"
        r="2.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="13"
        cy="16"
        r="2.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  homeMenu: (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none">
      <rect width="24" height="3" rx="1.5" fill="white" />
      <rect y="8" width="17" height="3" rx="1.5" fill="white" />
      <rect y="16" width="24" height="3" rx="1.5" fill="white" />
    </svg>
  ),
  homeMenuClose: (
    <svg width="24" height="19" viewBox="0 0 24 19" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.63586 1.06043C5.05007 0.474647 4.10032 0.474647 3.51454 1.06043C2.92875 1.64622 2.92875 2.59597 3.51454 3.18175L9.87862 9.54584L3.51476 15.9097C2.92898 16.4955 2.92898 17.4452 3.51476 18.031C4.10055 18.6168 5.0503 18.6168 5.63608 18.031L11.9999 11.6672L18.3638 18.031C18.9496 18.6168 19.8993 18.6168 20.4851 18.031C21.0709 17.4452 21.0709 16.4955 20.4851 15.9097L14.1213 9.54584L20.4853 3.18177C21.0711 2.59599 21.0711 1.64624 20.4853 1.06045C19.8995 0.474665 18.9498 0.474665 18.364 1.06045L11.9999 7.42452L5.63586 1.06043Z"
        fill="white"
      />
    </svg>
  ),
  walletFree: (
    <svg width="26" height="16" viewBox="0 0 26 16" fill="none">
      <rect width="25.3992" height="16" rx="4" fill="#0D0C22" />
      <g clipPath="url(#clip0_11724_242336)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5379 8.42951C17.2941 9.51891 16.3085 10.2453 15.2445 10.1856L16.2102 5.83687C16.6317 6.011 16.9896 6.31094 17.2406 6.70811C17.5656 7.22238 17.6712 7.83373 17.5379 8.42951ZM13.7713 12.1101C13.5621 12.2442 13.3138 12.2875 13.0723 12.2317C12.575 12.117 12.2619 11.6129 12.3745 11.108L13.1855 7.47049C13.4239 6.40484 14.358 5.66936 15.3787 5.66936C15.4122 5.66936 15.4458 5.67036 15.4794 5.67198L14.1804 11.522C14.1259 11.7671 13.9807 11.976 13.7713 12.1101ZM9.91844 10.1878L11.2175 4.33769C11.272 4.09263 11.4172 3.88375 11.6265 3.74966C11.8359 3.61553 12.0841 3.57233 12.3256 3.62805C12.823 3.74269 13.136 4.24685 13.0234 4.75179L12.2125 8.38922C11.9663 9.48979 10.9781 10.2383 9.91844 10.1878ZM8.15727 9.15156C7.83227 8.63734 7.72667 8.02599 7.86 7.43021C8.10367 6.34081 9.08927 5.61446 10.1533 5.67411L9.18769 10.0228C8.76618 9.84872 8.40823 9.54873 8.15727 9.15156ZM17.8709 6.29768C17.4367 5.61064 16.7637 5.13993 15.976 4.97224C15.1298 4.79208 14.2837 5.00915 13.6246 5.49862L13.7537 4.91951C13.9574 4.00613 13.3911 3.09427 12.4914 2.88685C12.0548 2.78616 11.6057 2.86426 11.227 3.10685C10.8483 3.34944 10.5856 3.72721 10.4872 4.17056L10.3197 4.92433C8.85129 4.77798 7.46515 5.76339 7.12982 7.26191C6.95167 8.05793 7.09279 8.87481 7.52706 9.56201C7.96123 10.2491 8.63421 10.7198 9.42199 10.8874C9.62136 10.9299 9.82067 10.9503 10.0176 10.9503C10.6563 10.9503 11.2694 10.7356 11.7732 10.3617L11.6442 10.9401C11.4406 11.8536 12.0068 12.7655 12.9065 12.9729C13.0308 13.0016 13.1562 13.0157 13.2806 13.0157C13.5932 13.0157 13.9001 12.9263 14.1709 12.7528C14.5495 12.5102 14.8123 12.1325 14.9108 11.6891L15.0782 10.9354C16.5475 11.0819 17.9329 10.0963 18.2681 8.59783C18.4462 7.80176 18.3052 6.98488 17.8709 6.29768Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11724_242336">
          <rect
            width="11.2886"
            height="11.4593"
            fill="white"
            transform="translate(7.05469 2.271)"
          />
        </clipPath>
      </defs>
    </svg>
  ),
  walletCustom: (
    <svg
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="25.3992"
        height="16"
        rx="4"
        fill="url(#paint0_linear_11724_242342)"
      />
      <g clipPath="url(#clip0_11724_242342)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5379 8.42951C17.2941 9.51891 16.3085 10.2453 15.2445 10.1856L16.2102 5.83687C16.6317 6.011 16.9896 6.31094 17.2406 6.70811C17.5656 7.22238 17.6712 7.83373 17.5379 8.42951ZM13.7713 12.1101C13.5621 12.2442 13.3138 12.2875 13.0723 12.2317C12.575 12.117 12.2619 11.6129 12.3745 11.108L13.1855 7.47049C13.4239 6.40484 14.358 5.66936 15.3787 5.66936C15.4122 5.66936 15.4458 5.67036 15.4794 5.67198L14.1804 11.522C14.1259 11.7671 13.9807 11.976 13.7713 12.1101ZM9.91844 10.1878L11.2175 4.33769C11.272 4.09263 11.4172 3.88375 11.6265 3.74966C11.8359 3.61553 12.0841 3.57233 12.3256 3.62805C12.823 3.74269 13.136 4.24685 13.0234 4.75179L12.2125 8.38922C11.9663 9.48979 10.9781 10.2383 9.91844 10.1878ZM8.15727 9.15156C7.83227 8.63734 7.72667 8.02599 7.86 7.43021C8.10367 6.34081 9.08927 5.61446 10.1533 5.67411L9.18769 10.0228C8.76618 9.84872 8.40823 9.54873 8.15727 9.15156ZM17.8709 6.29768C17.4367 5.61064 16.7637 5.13993 15.976 4.97224C15.1298 4.79208 14.2837 5.00915 13.6246 5.49862L13.7537 4.91951C13.9574 4.00613 13.3911 3.09427 12.4914 2.88685C12.0548 2.78616 11.6057 2.86426 11.227 3.10685C10.8483 3.34944 10.5856 3.72721 10.4872 4.17056L10.3197 4.92433C8.85129 4.77798 7.46515 5.76339 7.12982 7.26191C6.95167 8.05793 7.09279 8.87481 7.52706 9.56201C7.96123 10.2491 8.63421 10.7198 9.42199 10.8874C9.62136 10.9299 9.82067 10.9503 10.0176 10.9503C10.6563 10.9503 11.2694 10.7356 11.7732 10.3617L11.6442 10.9401C11.4406 11.8536 12.0068 12.7655 12.9065 12.9729C13.0308 13.0016 13.1562 13.0157 13.2806 13.0157C13.5932 13.0157 13.9001 12.9263 14.1709 12.7528C14.5495 12.5102 14.8123 12.1325 14.9108 11.6891L15.0782 10.9354C16.5475 11.0819 17.9329 10.0963 18.2681 8.59783C18.4462 7.80176 18.3052 6.98488 17.8709 6.29768Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11724_242342"
          x1="26.4289"
          y1="8"
          x2="-3.80483"
          y2="8.74807"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDA4A" />
          <stop offset="0.500382" stopColor="#FF00DB" />
          <stop offset="1" stopColor="#2577F1" />
        </linearGradient>
        <clipPath id="clip0_11724_242342">
          <rect
            width="11.2886"
            height="11.4593"
            fill="white"
            transform="translate(7.05469 2.271)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgIcons
